<template>
  <van-overlay
      :show="loadingOfWorkorderDetails">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="报修单详情页"
                 left-text="返回"
                 @click-left="router.back"/>
    <div class="page-container">
      <van-cell-group inset title="客户信息">
        <van-field label="客户姓名" readonly :model-value="workorder.workorder.clientName"/>
        <van-field label="客户电话" readonly :model-value="workorder.workorder.clientCellphone"/>
        <van-field label="客户地址" readonly :model-value="workorder.workorder.clientAddress"/>
      </van-cell-group>
      <van-cell-group inset title="工单信息">
        <van-field label="预约时间" readonly :model-value="workorder.workorder.appointmentTime"/>
        <van-field label="创建时间" readonly :model-value="workorder.workorder.createTime"/>
      </van-cell-group>
      <SmpFileCellGroup :files="workorder.workorder.files"/>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import SmpFileCellGroup from "../../../component/SmpFileCellGroup";
import {queryPrepareWorkorder} from "../../../api/workorder";
import {Toast} from "vant";
import {nameEncryption, phoneEncryption} from "../../../plugin/encryption";

const router = useRouter();
const urlParams = getUrlParam();

const workorder = reactive({
  workorder: {}
});

const loadingOfWorkorderDetails = ref(false);

const requestPrepareWorkorderInfo = () => {
  loadingOfWorkorderDetails.value = true;
  queryPrepareWorkorder(urlParams.workorderId).then(res => {
    res.data.clientName = nameEncryption(res.data.clientName);
    res.data.clientCellphone = phoneEncryption(res.data.clientCellphone);
    workorder.workorder = res.data;
    loadingOfWorkorderDetails.value = false;
  }).catch(err => {
    loadingOfWorkorderDetails.value = false;
    console.error("Request prepare workorder info failed", err);
    if (err.status === 40400) {
      Toast.fail({
        message: "暂无查询到此工单的信息",
        forbidClick: true
      });
    }
  });
};

onMounted(() => {
  validationUrlParam();
  if (!getUrlParam().workorderId) {
    router.back();
    Toast.fail({
      message: "暂无查询到工单ID",
      forbidClick: true
    });
  }
  requestPrepareWorkorderInfo();
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
